import {InputErrorManager} from "../InputValidationHandler";
import {ErrorVisibilityManager} from "../ErrorVisibilityManager";

export class DefaultPhoneInputErrorManager implements InputErrorManager {
    manageError = (element: HTMLInputElement, isValid: boolean) => {
        if (isValid) {
            ErrorVisibilityManager.hideFieldError(element);
        } else {
            ErrorVisibilityManager.showFieldError(element);
        }
    }
}
