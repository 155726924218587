import {EventBus} from "../../common/ts/events/EventBus";
import {ListingHighlightClickEvent} from "../../common/ts/events/Events";

export default class HipflatListingHighLightSection {

    private eventBus = EventBus.getInstance();

    constructor() {
        document.querySelectorAll(".listing-highlight__element")?.forEach((element) => {
            element.addEventListener('click', () => {
                this.eventBus.emit(new ListingHighlightClickEvent({
                    id: element.id,
                }));
            });
        });
    }

    static init = () => {
        return new HipflatListingHighLightSection();
    };
}
