import {MarketStatsChart, MarketStatsConfig, MarketStatsStyles} from "./MarketStatsChart";
import {ChartSelectorSubscriber} from "./ChartSelectorSubscriber";

export class SerpMarketStatsChart {

    init(styles: MarketStatsStyles, selector: string = '.js-market-stats-chart', chartSelectorSubscriber?: ChartSelectorSubscriber) {
        this.createMarketStatsChart(document.querySelector(selector), styles, chartSelectorSubscriber);
    }

    private createMarketStatsChart(chartCanvas: HTMLElement | null, styles: MarketStatsStyles, chartSelectorSubscriber: ChartSelectorSubscriber | undefined) {
        if (chartCanvas === null) {
            return;
        }
        const stats = chartCanvas.dataset.chartStats;
        if (stats === undefined) {
            return;
        }
        const statsDataset = JSON.parse(stats);
        const config = chartCanvas.dataset.chartConfig;
        if (config === undefined) {
            return;
        }
        const chartConfig: MarketStatsConfig = JSON.parse(config) as MarketStatsConfig;
        if (chartSelectorSubscriber !== undefined) {
            new MarketStatsChart(chartCanvas, statsDataset, chartConfig, styles, chartSelectorSubscriber);
        } else {
            new MarketStatsChart(chartCanvas, statsDataset, chartConfig, styles);
        }
    }
}
