import {InputValidator} from "../InputValidationHandler";
import PageData from "../../../PageData";
import FormValidations from "../FormValidations";
import CommonPhoneValidation from "./CommonPhoneValidation"


export class DefaultPhoneValidator implements InputValidator {
    private readonly pageData: PageData;

    constructor(pageData: PageData) {
        this.pageData = pageData;
    }

    isValid(element: HTMLInputElement) {
        if (CommonPhoneValidation.isMandatoryPhone(this.pageData)) {
            return this.isValidMandatoryPhone(element);
        }
        return this.isValidPhone(element);
    }

    private isValidPhone(element: HTMLInputElement) {
        return FormValidations.isValidPhone(element.value);
    }

    private isValidMandatoryPhone(element: HTMLInputElement) {
        return FormValidations.isValidMandatoryPhone(element.value);
    }
}