import {ApplyOperationType} from "../services/AdFormService";

export class OperationSelect {
    private readonly saleElement: HTMLInputElement | null;
    private readonly rentElement: HTMLInputElement | null;
    private onChangeCallback: CallableFunction = () => {}

    private operation: string;

    private readonly saleValue = "SALE"
    private readonly rentValue = "RENT"

    static init(form: HTMLElement | null) {
        let rent = form?.querySelector('.js-operation-type.rent') as HTMLInputElement;
        let sale = form?.querySelector('.js-operation-type.sale') as HTMLInputElement;
        let operation = form?.querySelector('.js-operation-type.operation') as HTMLInputElement;
        if (rent == null && sale == null && operation == null) {
            return null;
        }
        return new OperationSelect(rent, sale, operation)
    }

    constructor(rentElement: HTMLInputElement, saleElement: HTMLInputElement, operation: HTMLInputElement) {
        this.saleElement = saleElement;
        this.rentElement = rentElement;
        this.operation = operation?.value;

        this.saleElement?.addEventListener('change', (event) => this.onChangeCallback(this.saleValue))
        this.rentElement?.addEventListener('change', (event) => this.onChangeCallback(this.rentValue))
    }

    public onChange(callback: CallableFunction) {
        this.onChangeCallback = function(operationValue: string) {
            this.operation = operationValue;
            callback()
        }
    }

    public selectedOperation() {
        if (this.isSaleSelected()) {
            return ApplyOperationType.SALE;
        } else if (this.isRentSelected()) {
            return ApplyOperationType.RENT;
        }
        return null
    }

    public isSaleSelected(): Boolean {
        return this.operation == this.saleValue;
    }

    public isRentSelected() {
        return this.operation == this.rentValue;
    }
}
