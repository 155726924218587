import PageData from '../../common/ts/PageData';
import HipflatModalPhotosSection from './HipflatModalPhotosSection'
import HipflatPhotosSection from "./HipflatPhotosSection";
import HipflatListingHighLightSection from "./HipflatListingHighLightSection";
import HipflatForm from "./HipflatForm";
import ProjectUnits from "./common/ProjectUnits";
import HipflatContactModal from "./HipflatContactModal";
import HipflatStickyButtons from "./common/HipflatStickyButtons";
import {WebVitalsTracking} from "../../common/ts/WebVitalsTracking";
import {
    MarketStatsMultiChartSelectorSubscriber,
    MarketStatsStyles
} from "../../common/ts/components/charts/MarketStatsChart";
import {SerpMarketStatsChart} from "../../common/ts/components/charts/SerpMarketStatsChart";
import '@justinribeiro/lite-youtube';

window.addEventListener("load",() => {
    // @ts-ignore
    const pageData: PageData = window.wl.pageData

    HipflatStickyButtons.init(pageData);
    HipflatListingHighLightSection.init();
    HipflatForm.init(pageData);
    HipflatContactModal.init(pageData);

    ProjectUnits.init(pageData);
    HipflatModalPhotosSection.init(pageData);
    HipflatPhotosSection.init(pageData);
    marketStats()

    WebVitalsTracking.init("Project Page")
});


function marketStats() {
    const marketStatsStyles: MarketStatsStyles = {
        lineColor: '#FD6E6E',
        fillColor: '#FD6E6E1A',
        pointColor: '#FFFFFF',
        pointHoverColor: '#FD6E6E',
        scalesLabelColor: '#8F939F',
        pointRadius: 8,
        tickPadding: 10,
        tooltipTitleFontSize: 14,
        tooltipBodyFontSize: 16,
    };
    const marketStatsChartSelector = new MarketStatsMultiChartSelectorSubscriber(1, ".market-stats-project-operation", ".market-stats-project-price-type");
    new SerpMarketStatsChart().init(marketStatsStyles, '.js-market-stats-chart', marketStatsChartSelector);
}