export class ErrorVisibilityManager {
    static manageErrorVisibility(element: HTMLInputElement, isValid: boolean) {
        if (isValid) {
            this.hideFieldError(element)
            return true
        } else {
            this.showFieldError(element);
            return false
        }
    }

    static showFieldError(element: HTMLInputElement) {
        this.showInputError(element);
        this.showDefaultDivError(element);
    }

    static hideFieldError(element: HTMLInputElement) {
        this.hideInputError(element);
        this.hideDefaultDivError(element);
    }

    static showDefaultDivError(element: HTMLInputElement) {
        this.getDefaultErrorMessageElement(element)?.classList.add('active');
    }

    static hideDefaultDivError(element: HTMLInputElement) {
        this.getDefaultErrorMessageElement(element)?.classList.remove('active');
    }

    static getInputContainer(element: HTMLInputElement) {
        return element.closest('.adform-input-wrapper')
    }

    static showInputError(element: HTMLInputElement) {
        element.classList.add('invalid');
    }

    static hideInputError(element: HTMLInputElement) {
        element.classList.remove('invalid');
    }

    private static getDefaultErrorMessageElement(element: HTMLInputElement) {
        return this.getInputContainer(element)?.querySelector('.error-text')
    }
}
