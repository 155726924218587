import {onCLS, onFID, onLCP, onINP, onFCP, onTTFB, Metric} from 'web-vitals';
import {sendMetric} from "./tracking/api";

export class WebVitalsTracking {

    static transactionName = window.location.toString()

    static init(name: string) {
        WebVitalsTracking.transactionName = name
        new WebVitalsTracking()
    }

    private readonly queue: Set<WLMetric>

    constructor() {
        this.queue = new Set<WLMetric>();
        onCLS(this.addToQueue.bind(this));
        onFID(this.addToQueue.bind(this));
        onLCP(this.addToQueue.bind(this));
        onINP(this.addToQueue.bind(this));
        onFCP(this.addToQueue.bind(this));
        onTTFB(this.addToQueue.bind(this));

        addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'hidden') {
                this.flushQueue();
            }
        });

        addEventListener('pagehide', this.flushQueue.bind(this));
    }

    private addToQueue(metric: Metric) {
        this.queue.add(new WLMetric(metric, WebVitalsTracking.transactionName));
    }

    private flushQueue() {
        if (this.queue.size > 0) {
            const body = JSON.stringify(Array.from(this.queue));
            sendMetric('/api/webvitals', body)
        }
    }
}

class WLMetric {
    readonly metric: Metric
    readonly name: string

    constructor(metric: Metric, name: string) {
        this.metric = metric
        this.name = name
    }

    public toJSON() {
        return {
            name: this.name,
            metric: this.metric
        }
    }
}