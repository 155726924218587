import {ChartJs} from "./ChartJs";
import Chart, {ChartDataset, TooltipItem} from "chart.js/auto";
import {WlSubmit} from "../../webcomponents/events/WlSubmit";
import {ChartData, ChartOptions} from "chart.js";
import {ChartSelectorSubscriber} from "./ChartSelectorSubscriber";

export interface MarketStatsConfig {
    locale: string;
}

export interface MarketStatsStyles {
    lineColor: string;
    fillColor: string;
    pointColor: string;
    pointHoverColor: string;
    scalesLabelColor: string;
    pointRadius: number;
    tickPadding: number;
    tooltipTitleFontSize: number;
    tooltipBodyFontSize: number;
}

interface MarketStatsSummaryComponentData {
    value: number;
    date: string;
}

interface MarketStatsSummaryComponentDataset {
    currencyStringFormat: string;
    data: MarketStatsSummaryComponentData[];
}

class DefaultChartSelectorSubscriber implements ChartSelectorSubscriber {
    private readonly MEDIAN_PRICE_INPUT_VALUE: string = 'median-price';
    private readonly PRICE_PER_AREA_INPUT_VALUE: string = 'price-per-area';
    private readonly MEDIAN_PRICE_DATASET_INDEX: number = 0;
    private readonly PRICE_PER_AREA_DATASET_INDEX: number = 1;
    private readonly DEFAULT_DATASET_INDEX: number = this.PRICE_PER_AREA_DATASET_INDEX;

    subscribe(chart: MarketStatsChart) {
        const chartSelector = document.querySelector(".market-stats-selector");
        if (chartSelector === null) return;
        chartSelector.addEventListener(WlSubmit.type, () => {
            const value: string = (chartSelector as HTMLInputElement).value;
            if (value === this.MEDIAN_PRICE_INPUT_VALUE) {
                chart.showDataset(this.MEDIAN_PRICE_DATASET_INDEX);
            }
            if (value === this.PRICE_PER_AREA_INPUT_VALUE) {
                chart.showDataset(this.PRICE_PER_AREA_DATASET_INDEX);
            }
        });
    }

    getDefaultDatasetIndex(): number {
        return this.DEFAULT_DATASET_INDEX;
    }
}

export class MarketStatsMultiChartSelectorSubscriber implements ChartSelectorSubscriber {
    private readonly selector1: string;
    private readonly selector2: string;
    private readonly defaultSelected: number;

    constructor(defaultSelected: number, selector1: string, selector2: string) {
        this.selector1 = selector1;
        this.selector2 = selector2;
        this.defaultSelected = defaultSelected;
    }

    subscribe(chart: MarketStatsChart): void {
        const chartSelector1 = document.querySelector(this.selector1);
        const chartSelector2 = document.querySelector(this.selector2);
        const getValue = (selector: Element): string => (selector as HTMLInputElement).value;
        const selectedIndex = (selector1Value: string, selected2Value: string): number => {
            /**
             * 0,0 -> 0
             * 0,1 -> 1
             * 1,0 -> 2
             * 1,1 -> 3
             * 2,0 -> 4
             * 2,1 -> 5
             * 3,0 -> 6
             * 3,1 -> 7
             */
            return parseInt(selector1Value) * 2 + parseInt(selected2Value);
        };
        if (chartSelector1 === null || chartSelector2 == null) return;
        const clickListener = () => {
            const selector1Value: string = getValue(chartSelector1);
            const selector2Value: string = getValue(chartSelector2);
            chart.showDataset(selectedIndex(selector1Value, selector2Value));
        };
        chartSelector1.addEventListener(WlSubmit.type, clickListener);
        chartSelector2.addEventListener(WlSubmit.type, clickListener);
    }

    getDefaultDatasetIndex(): number {
        return this.defaultSelected;
    }
}

export class MarketStatsChart {
    private readonly chartCanvas: HTMLElement | null = null;
    private readonly statsDataset: MarketStatsSummaryComponentDataset[];
    private readonly chartConfig: MarketStatsConfig;
    private readonly _chart: ChartJs | undefined;
    private readonly _styles: MarketStatsStyles;

    constructor(chartCanvas: HTMLElement,
                statsDataset: MarketStatsSummaryComponentDataset[],
                chartConfig: MarketStatsConfig,
                styles: MarketStatsStyles,
                chartSelectorSubscriber: ChartSelectorSubscriber = new DefaultChartSelectorSubscriber()
    ) {
        this.chartCanvas = chartCanvas;
        this.statsDataset = statsDataset;
        this.chartConfig = chartConfig;
        this._styles = styles;
        this._chart = this.createChart(chartSelectorSubscriber.getDefaultDatasetIndex());
        chartSelectorSubscriber.subscribe(this);
    }

    private createChart(defaultDatasetIndex: number) {
        if (this.chartCanvas === null) {
            return;
        }
        this.configureChartFont()
        const data = this.generateDataset(defaultDatasetIndex);
        const selectedDataset = this.statsDataset[defaultDatasetIndex];
        const options = this.configureOptions(selectedDataset);
        return new ChartJs(this.chartCanvas, 'line', data, defaultDatasetIndex, options);
    }

    private configureChartFont() {
        Chart.defaults.font.size = parseInt(getComputedStyle(this.chartCanvas!!).fontSize);
    }

    private configureOptions(selectedDataset: MarketStatsSummaryComponentDataset): ChartOptions {
        const ticksStyles = {
            color: this._styles.scalesLabelColor,
            padding: this._styles.tickPadding,
        };
        const minDatasetValue = Math.min(...selectedDataset.data.map(data => data.value));
        const maxDatasetValue = Math.max(...selectedDataset.data.map(data => data.value));
        const scalesYMinAndMax: { min: number, max: number } = {
            min: this.calculateMinLimit(minDatasetValue, maxDatasetValue),
            max: this.calculateMaxLimit(minDatasetValue, maxDatasetValue),
        };
        return {
            animation: false,
            locale: this.chartConfig.locale,
            layout: {
                padding: 0,
            },
            scales: {
                x: {
                    grid: {
                        display: false
                    },
                    ticks: {
                        maxRotation: 90,
                        maxTicksLimit: 13,
                        ...ticksStyles,
                    }
                },
                y: {
                    type: 'linear',
                    ...scalesYMinAndMax,
                    ticks: {
                        format: {
                            style: "decimal",
                            maximumFractionDigits: 0
                        },
                        /** @ts-ignore */
                        callback: function (value: number, index: number, ticks: { value: number; }[]) {
                            if (!value) return 0;
                            const units = ['', 'K', 'M', 'B'];
                            const k = 1000;
                            const magnitude = Math.floor(Math.log(value) / Math.log(k));
                            const number: string = `${value / Math.pow(k, magnitude)}${units[magnitude]}`;
                            return selectedDataset.currencyStringFormat.replace('%s', number);
                        },
                        ...ticksStyles,
                    }
                },
            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    callbacks: {
                        label(tooltipItem: TooltipItem<any>): string {
                            return selectedDataset.currencyStringFormat.replace('%s', tooltipItem.formattedValue);
                        }
                    },
                    xAlign: 'center',
                    yAlign: 'bottom',
                    titleColor: '#FFFFFF',
                    titleFont: {
                        weight: 'bold',
                        size: this._styles.tooltipTitleFontSize,
                    },
                    bodyColor: '#FFFFFF',
                    bodyFont: {
                        size: this._styles.tooltipBodyFontSize,
                    },
                    displayColors: false,
                }
            },
        };
    }

    private calculateMaxLimit(minMedianPriceDatasetValue: number, maxMedianPriceDatasetValue: number) {
        return this.roundToMostSignificantDigit(maxMedianPriceDatasetValue + this.chartPriceVerticalMargin(minMedianPriceDatasetValue, maxMedianPriceDatasetValue), Math.ceil);
    }

    private calculateMinLimit(minMedianPriceDatasetValue: number, maxMedianPriceDatasetValue: number): number {
        const number = minMedianPriceDatasetValue - this.chartPriceVerticalMargin(minMedianPriceDatasetValue, maxMedianPriceDatasetValue);
        return number > 0 ? this.roundToMostSignificantDigit(number, Math.floor) : 0;
    }

    private chartPriceVerticalMargin(minMedianPriceDatasetValue: number, maxMedianPriceDatasetValue: number) {
        if (maxMedianPriceDatasetValue == minMedianPriceDatasetValue) {
            return Math.round(maxMedianPriceDatasetValue * 0.1)
        }
        return Math.round((maxMedianPriceDatasetValue - minMedianPriceDatasetValue) / 2)
    }

    private roundToMostSignificantDigit(num: number, roundType: (value: number) => number) {
        if (num === 0) {
            return 0;
        }
        const magnitudeOneDigit = Math.floor(Math.log10(Math.abs(num)));
        let magnitude = magnitudeOneDigit - 1;
        let normalizedNum = num / Math.pow(10, magnitude);
        let roundedNum = roundType(normalizedNum);
        return roundedNum * Math.pow(10, magnitude);
    }

    private generateDataset(defaultDatasetIndex: number): ChartData {
        const toDataset = (data: MarketStatsSummaryComponentData): any => ({x: data.date, y: data.value});
        const pointRadius = this._styles.pointRadius;
        const datasetCommonProps: Partial<ChartDataset> = {
            fill: 'origin',
            borderColor: this._styles.lineColor,
            borderWidth: 2,
            backgroundColor: this._styles.fillColor,
            pointBackgroundColor: this._styles.pointColor,
            pointHoverBackgroundColor: this._styles.pointHoverColor,
            pointHoverRadius:  pointRadius,
            pointHitRadius: pointRadius + 2,
            pointRadius: pointRadius,
            pointBorderWidth: 2,
        };
        const datasets: ChartData['datasets'] = this.statsDataset
            .map((dataset: MarketStatsSummaryComponentDataset, index: number) => {
                return {
                    ...datasetCommonProps,
                    data: dataset.data.map(toDataset),
                    hidden: defaultDatasetIndex !== index,
                };
            });
        return {
            datasets: datasets,
        };
    }

    showDataset(datasetIndex: number) {
        const datasetIndexToHide: number = this._chart!.getSelectedDatasetIndex();
        const dataset: MarketStatsSummaryComponentDataset = this.statsDataset[datasetIndex];
        this._chart!.hide(datasetIndexToHide);
        this._chart!.updateOptions(this.configureOptions(dataset));
        this._chart!.show(datasetIndex);
    }
}
