import PageData from '../../../PageData';
import {InternationalPhoneInputInterface} from "./InternationalPhoneInput";
import {InputErrorManager, InputValidationHandler, InputValidator} from "../InputValidationHandler";
import {DefaultPhoneValidator} from "./DefaultPhoneValidator";
import {InternationalPhoneValidator} from "./InternationalPhoneValidator";
import {DefaultPhoneInputErrorManager} from "./DefaultPhoneInputErrorManager";
import {MultiPhoneMessageInputErrorManager} from "./MultiPhoneMessageInputErrorManager";
import {InternationalPhoneInputErrorManager} from "./InternationalPhoneInputErrorManager";

export class PhoneValidationFactory {
    static create(pageData: PageData, element: HTMLInputElement, internationalPhoneInput?: InternationalPhoneInputInterface) {
        return new InputValidationHandler(
            element,
            this.getInputValidator(pageData, internationalPhoneInput),
            this.getErrorManager(pageData, internationalPhoneInput)
        );
    }

    private static getInputValidator(pageData: PageData, internationalPhoneInput?: InternationalPhoneInputInterface): InputValidator {
        if (internationalPhoneInput) {
            return new InternationalPhoneValidator(pageData, internationalPhoneInput!!);
        } else {
            return  new DefaultPhoneValidator(pageData);
        }
    }

    private static getErrorManager(pageData: PageData, internationalPhoneInput?: InternationalPhoneInputInterface): InputErrorManager {
        if (internationalPhoneInput) {
            return new InternationalPhoneInputErrorManager();
        } else if (this.hasMultipleErrorMessages(pageData)) {
            return new MultiPhoneMessageInputErrorManager(pageData);
        } else {
            return new DefaultPhoneInputErrorManager();
        }
    }


    private static hasMultipleErrorMessages(pageData: PageData) {
        return pageData.validation && pageData.validation.hasMultipleErrorMessages;
    }
}
