import {InputErrorManager} from "../InputValidationHandler";
import {DefaultPhoneInputErrorManager} from "./DefaultPhoneInputErrorManager";
import {ErrorVisibilityManager} from "../ErrorVisibilityManager";

export class InternationalPhoneInputErrorManager implements InputErrorManager {

    private readonly defaultInputErrorManager: InputErrorManager;

    constructor() {
        this.defaultInputErrorManager = new DefaultPhoneInputErrorManager();
    }

    manageError = (element: HTMLInputElement, isValid: boolean) => {
        this.defaultInputErrorManager.manageError(element, isValid);
        this.handlePhoneIncorrectError(element, isValid);
    }

    private handlePhoneIncorrectError(element: HTMLInputElement, isValid: boolean) {
        if (isValid) {
            this.getPhoneIncorrectMessageElement(element)?.classList.remove('active');
        } else {
            ErrorVisibilityManager.hideDefaultDivError(element);
            this.getPhoneIncorrectMessageElement(element)?.classList.add('active');
        }
    }

    private getPhoneIncorrectMessageElement(element: HTMLInputElement) {
        return ErrorVisibilityManager.getInputContainer(element)?.querySelector('.phone-incorrect');
    }
}
