import 'intl-tel-input/build/css/intlTelInput.min.css';
import intlTelInput from 'intl-tel-input';

export interface InternationalPhoneInputInterface {
    getNumber(): string
    setNumber(number: string): void
    isPhoneValid(): boolean
}

export class InternationalPhoneInput implements InternationalPhoneInputInterface {
    static init(phoneInput: HTMLInputElement,
                initialCountry: string,
                preferredCountries: string[],
    ) {
        return new InternationalPhoneInput(phoneInput, initialCountry, preferredCountries)
    }

    private readonly plugin: intlTelInput.Plugin;
    private readonly detectedCountryLocalStorageKey: string = "detectedCountry";

    private constructor(phoneInput: HTMLInputElement,
                        initialCountry: string,
                        preferredCountries: string[],
                        detectCountry: boolean = true) {
        let country = initialCountry
        if (detectCountry) {
            const detectedCountry = localStorage.getItem(this.detectedCountryLocalStorageKey);
            if (detectedCountry) {
                country = detectedCountry.toLowerCase()
            } else {
                country = "auto"
            }
        }
        this.plugin = intlTelInput(phoneInput,
            {
                separateDialCode: true,
                preferredCountries: preferredCountries,
                utilsScript: "/adform/intl-tel-input/utils.js",
                formatOnDisplay: true,
                initialCountry: country,
                geoIpLookup: this.setPhoneCountryByIP(initialCountry)
            }
        );
    }

    private setPhoneCountryByIP(initialCountry: string) {
        return (callback: (arg0: string) => void) => {
            try {
                fetch("https://api.country.is/")
                    .then(res => res.json())
                    .then(data => {
                        if (data.country) {
                            callback(data.country);
                            localStorage.setItem(this.detectedCountryLocalStorageKey, data.country)
                        } else {
                            callback(initialCountry)
                        }
                    })
                    .catch(() => callback(initialCountry));
            } catch (e) {
                console.log(e)
                callback(initialCountry);
            }
        };
    }

    public getNumber() {
        return this.plugin.getNumber()
    }

    public setNumber(number: string) {
        return this.plugin.setNumber(number)
    }

    public isPhoneValid() {
        return this.plugin.isValidNumber() === null || this.plugin.isValidNumber()
    }
}
