// https://www.npmjs.com/package/js-datepicker

/* eslint @typescript-eslint/no-var-requires: "off" */
import "js-datepicker/dist/datepicker.min.css"
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

const datepicker: any = require('js-datepicker')

export class RentDatesDatepicker {
    private readonly container: HTMLElement
    private readonly startElement: HTMLInputElement
    private readonly endElement: HTMLInputElement

    static init(form: HTMLElement | null) {
        let container = form?.querySelector('.adform-rent-dates') as HTMLElement;
        let startElement = form?.querySelector('.js-date-start') as HTMLInputElement;
        let endElement = form?.querySelector('.js-date-end') as HTMLInputElement;

        if (container == null || startElement == null || endElement == null) {
            return null
        }
        return new RentDatesDatepicker(container, startElement, endElement)
    }

    private constructor(
        container: HTMLElement,
        startElement: HTMLInputElement,
        endElement: HTMLInputElement,
    ) {
        this.container = container;
        this.startElement = startElement;
        this.endElement = endElement;

        const datePickerId = startElement.id;
        this.createDatePicker(startElement, datePickerId)
        this.createDatePicker(endElement, datePickerId)
    }

    private readonly DATE_FORMAT = "DD/MM/YYYY";

    public show() {
        this.container.classList.remove("hide")
    }

    public hide() {
        this.container.classList.add("hide")
    }

    public validate(): Boolean {
        if (this.container.classList.contains("hide")) {
            return true
        }
        let startEmpty = this.startElement.value == "";
        let endEmpty = this.endElement.value == "";

        this.manageValid(this.startElement, startEmpty);
        this.manageValid(this.endElement, endEmpty);

        return !(startEmpty || endEmpty);
    }

    private manageValid(element: HTMLElement, nonValid: Boolean) {
        let errorMessage = element
            .closest('.adform-input-wrapper')?.querySelector('.error-text') || null;

        element.classList.remove('invalid');
        errorMessage?.classList.remove('active');

        if (nonValid) {
            element.classList.add("invalid");
            errorMessage?.classList.add('active');
        }
    }

    public getValue(): RentDates {
        let start = dayjs(this.startElement.value, this.DATE_FORMAT);
        let end = dayjs(this.endElement.value, this.DATE_FORMAT);
        return new RentDates(
            start.toDate(),
            end.toDate()
        )
    }

    private createDatePicker(dateElement: HTMLInputElement, datePickerId: string) {
        return datepicker("#" + dateElement.id, {
            id: datePickerId,
            formatter: (input: any, date: Date, instance: any) => {
                input.value = this.formatDate(date);
            },
            minDate: new Date(),
            onShow: (instance: any) => this.onDatepickerShow(instance)
        });
    }

    private formatDate(date: Date) {
        return dayjs(date).format(this.DATE_FORMAT)
    }

    private onDatepickerShow(instance: any) {
        instance.calendarContainer.style.top = (instance.positionedEl.offsetHeight + 5) + 'px';
        instance.calendarContainer.style.left = '-1px';
        this.preventDoesntCompletelyVisible(instance);
    }

    private preventDoesntCompletelyVisible(instance: any) {
        let calendarContainer = instance.calendarContainer;
        if (calendarContainer.getBoundingClientRect().right > document.documentElement.clientWidth) {
            calendarContainer.style.right = this.getInitialX(instance);
            calendarContainer.style.left = '';
        } else {
            calendarContainer.style.left = this.getInitialX(instance)
            calendarContainer.style.right = '';
        }
        if (calendarContainer.getBoundingClientRect().bottom > document.documentElement.clientHeight) {
            calendarContainer.style.bottom = this.getInitialY(instance);
            calendarContainer.style.top = '';
        } else {
            calendarContainer.style.top = this.getInitialY(instance);
            calendarContainer.style.bottom = '';
        }
    }

    private getInitialY(instance: any) {
        let calendarContainer = instance.calendarContainer;
        if (calendarContainer.style.initialY == undefined) {
            calendarContainer.style.initialY = calendarContainer.style.top;
        }
        return calendarContainer.style.initialY;
    }

    private getInitialX(instance: any) {
        let calendarContainer = instance.calendarContainer;
        if (calendarContainer.style.initialX == undefined) {
            calendarContainer.style.initialX = calendarContainer.style.left;
        }
        return calendarContainer.style.initialX;
    }
}

export class RentDates {
    public start: Date;
    public end: Date;

    constructor(start: Date, end: Date) {
        this.start = start;
        this.end = end;
    }
}
