import PageData from '../PageData';
import {PhoneTrackingResponse} from "../types/PhoneTrackingResponse";
import WhatsappService from "./WhatsappService";

export default class LeadTrackingService {
    private readonly pageData: PageData;
    private readonly PHONE_AUTOMATIC_API_URI_ENDPOINT: string = '/adform/api/phone/automatic/';
    private readonly PHONE_MANUAL_API_URI_ENDPOINT: string = '/adform/api/phone/manual/';

    constructor(pageData: PageData) {
        this.pageData = pageData
    }

    public getWhatsappData = (section: number) => new WhatsappService().getWhatsappData(
        section,
        this.pageData.chatsData,
        this.pageData.country,
        this.pageData.device,
        this.pageData.id,
        this.pageData.pageViewId
    )


    public getPhoneAutomaticData = async (id: string): Promise<PhoneTrackingResponse> => {
        const email = localStorage.getItem("email");
        if (!email) return {data: null};
        const request = await fetch(`${this.PHONE_AUTOMATIC_API_URI_ENDPOINT}${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify({
                email: email,
                pageViewId: this.pageData.pageViewId
            }),
        });

        if (request.status !== 200) return {data: null};

        return request.json();
    }

    public getPhoneManualData = async (id: string): Promise<PhoneTrackingResponse> => {
        const email = localStorage.getItem("email");
        if (!email) return {data: null};
        const request = await fetch(`${this.PHONE_MANUAL_API_URI_ENDPOINT}${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify({
                email: email,
                pageViewId: this.pageData.pageViewId
            }),
        });

        if (request.status !== 200) return {data: null};

        return request.json();
    }

}
