import PageData from "../../../common/ts/PageData";
import {EventBus} from "../../../common/ts/events/EventBus";
import {Section} from "../../../common/ts/services/Section";
import {StickyContactButtonClickedEvent, StickyScheduleButtonClickedEvent} from "../../../common/ts/events/Events";
import AdFormService from "../../../common/ts/services/AdFormService";
import {expose} from "../../../common/ts/components/core/amplitude";
import {ButtonClicked, track} from "../../../common/ts/tracking/WebTracking";

export default class HipflatStickyButtons {

    constructor(pageData: PageData) {
        let adFormService: AdFormService = new AdFormService()
        const eventBus: EventBus = EventBus.getInstance();
        document.querySelectorAll(".js-request-sticky").forEach(button => {
            if (this.isALternativePath(button)) {
                this.configureScrollOnClick(button, pageData)
            } else {
                this.configureSticky(pageData, button, eventBus, adFormService);
            }
            expose();
        });
        document.querySelectorAll(".js-schedule-sticky").forEach(button => {
            button.addEventListener('click', (event) => {
                eventBus.emit(new StickyScheduleButtonClickedEvent({
                    step: 2,
                    section: Section.SECTION_ADPAGE_STICKY_BOTTOM_VISIT,
                    pageViewId: pageData.pageViewId,
                    propertyAdId: pageData.id,
                    pageViewType: pageData.pageViewType,
                }));
            });
            adFormService.sendApplyImpression({
                pageViewId: pageData.pageViewId,
                propertyAdId: pageData.id,
                section: Section.SECTION_ADPAGE_STICKY_BOTTOM_VISIT,
                step: 1,
                isProject: false,
            });
        });
    }

    private isALternativePath(button: Element) {
        return button.hasAttribute("data-goto-main-form") && button.getAttribute("data-goto-main-form") == 'true';
    }

    private configureSticky(pageData: PageData, button: Element, eventBus: EventBus, adFormService: AdFormService) {
        const propertyAdId = pageData.id;

        let isProject = true;
        let section: Section = Section.SECTION_ADPAGE_STICKY_BOTTOM_CONTACT_FORM;
        if (pageData.pageViewType == "DETAIL_PAGE") {
            isProject = false;
        }

        button.addEventListener('click', (event) => {
            eventBus.emit(new StickyContactButtonClickedEvent({
                step: 2,
                section: section,
                pageViewId: pageData.pageViewId,
                propertyAdId: propertyAdId,
                pageViewType: pageData.pageViewType,
            }));
        });

        adFormService.sendApplyImpression({
            pageViewId: pageData.pageViewId,
            propertyAdId: pageData.id,
            section: section,
            step: 1,
            isProject: isProject,
        });
    }

    private configureScrollOnClick(button: Element, pageData: PageData) {
        button.addEventListener('click', (event) => {
            document.getElementById("adform-contact-form")?.scrollIntoView();
            //track(new ButtonClicked(pageData.id, "sticky_button"))
        });
    }

    static init(pageData: PageData) {
        return new HipflatStickyButtons(pageData);
    }
}