import PageData from '../../../PageData';
import {InputValidationHandler} from "../InputValidationHandler";
import {DefaultEmailValidator} from "./DefaultEmailValidator";
import {MultiMessageEmailInputErrorManager} from "./MultiMessageEmailInputErrorManager";
import {DefaultEmailInputErrorManager} from "./DefaultEmailInputErrorManager";

export class EmailValidationFactory {
    static create(pageData: PageData, element: HTMLInputElement) {
        return new InputValidationHandler(
            element,
            new DefaultEmailValidator(pageData),
            this.getErrorManager(pageData)
        );
    }

    private static getErrorManager(pageData: PageData) {
        if (this.hasMultipleErrorMessages(pageData)) {
            return new MultiMessageEmailInputErrorManager(pageData);
        } else {
            return new DefaultEmailInputErrorManager();
        }
    }

    private static hasMultipleErrorMessages(pageData: PageData) {
        return pageData.validation && pageData.validation.hasMultipleErrorMessages;
    }
}
