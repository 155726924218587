import {InputErrorManager} from "../InputValidationHandler";
import PageData from "../../../PageData";
import {DefaultPhoneInputErrorManager} from "./DefaultPhoneInputErrorManager";
import FormValidations from "../FormValidations";
import CommonPhoneValidation from "./CommonPhoneValidation"
import {ErrorVisibilityManager} from "../ErrorVisibilityManager";

export class MultiPhoneMessageInputErrorManager implements InputErrorManager {
    private readonly pageData: PageData;
    private readonly defaultInputErrorManager: InputErrorManager;

    constructor(pageData: PageData) {
        this.pageData = pageData;
        this.defaultInputErrorManager = new DefaultPhoneInputErrorManager();
    }

    manageError = (element: HTMLInputElement, isValid: boolean) => {
        this.changeErrorMessage(element);
        this.defaultInputErrorManager.manageError(element, isValid);
    }

    private changeErrorMessage(element: HTMLInputElement) {
        if (CommonPhoneValidation.isMandatoryPhone(this.pageData)) {
            this.changeErrorMessageForMandatoryPhone(element);
        } else if (!CommonPhoneValidation.isValidPhone(element)) {
            this.changeErrorMessageOf(element, this.pageData.validation!!.errorMessages.phone.incorrectFormat)
        }
    }

    private changeErrorMessageForMandatoryPhone(element: HTMLInputElement) {
        if (FormValidations.isEmpty(element.value)) {
            this.changeErrorMessageOf(element, this.pageData.validation!!.errorMessages.phone.empty)
        } else if (!CommonPhoneValidation.isValidPhone(element)) {
            this.changeErrorMessageOf(element, this.pageData.validation!!.errorMessages.phone.incorrectFormat)
        }
    }

    private changeErrorMessageOf(element: HTMLInputElement, errorMessage: string | null) {
        let errorMessageElement = this.getDefaultErrorMessageElement(element)
        if (errorMessage && errorMessageElement) {
            errorMessageElement.innerHTML = errorMessage;
        }
    }

    private getDefaultErrorMessageElement(element: HTMLInputElement) {
        return ErrorVisibilityManager.getInputContainer(element)?.querySelector('.error-text')
    }
}