import ChatsData from "../types/ChatsData";

export default class WhatsappService {
    private readonly CHAT_API_URI_ENDPOINT: string = '/adform/api/conversation/';

    public getWhatsappData = (
        section: number,
        chatData: ChatsData,
        country: string,
        device: string,
        adId: string,
        pageViewId: string
    ) => fetch(this.CHAT_API_URI_ENDPOINT + adId, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({
            channel: 'whatsapp',
            brandId: chatData.branId,
            languageCode: chatData.countryLanguage.toLowerCase(),
            countryCode: country.toLowerCase(),
            detailPageUrl: chatData.adUrl,
            internalStatsApplyData: {
                deviceType: device.toLowerCase(),
                userAgent: chatData.userAgent,
                ipAddress: chatData.ipAddress,
                originId: chatData.origin,
                pageViewId: pageViewId,
                sectionId: section,
                adId: adId,
            },
        }),
    });


}
