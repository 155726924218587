import {WhitelabelLanguageDetector} from "../../../common/ts/core/WhitelabelLanguageDetector";

const languagePrefix = new WhitelabelLanguageDetector().detectLanguage();

export default class HipflatProjectUnitsService {
  public async getProjectUnits(pageViewId: string,
                               projectId: string,
                               operationType: string,
                               bedrooms: string,
                               page: number,
                               newUnitsSnippetTable: boolean,
                               sorting: string) {
    const url = `${languagePrefix}/projectUnits/${projectId}/${operationType}/?pageViewId=${pageViewId}&bedrooms=${bedrooms}&page=${page}&newUnitsSnippetTable=${newUnitsSnippetTable}&sorting=${sorting}`
    const request = await fetch(url, {
      method: 'GET'
    });
    return request.text();
  }
}
