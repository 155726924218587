import {RentDatesDatepicker} from "./RentDatesDatepicker";
import {OperationSelect} from "./OperationSelect";
import {ApplyOperationType} from "../services/AdFormService";

export interface OperationsInterface {
    validate(): Boolean | undefined
}

export class Operations implements OperationsInterface{
    private readonly rentDates: RentDatesDatepicker | null;
    private readonly select: OperationSelect | null;

    constructor(form: HTMLElement | null) {
        this.rentDates = RentDatesDatepicker.init(form);
        this.select = OperationSelect.init(form);

        this.manageDateVisibility();
        this.select?.onChange(() => {
            this.manageDateVisibility();
        });
    }

    public validate() {
        let dontCheckRent = this.select?.isSaleSelected() || this.rentDates == null;
        return dontCheckRent || this.rentDates?.validate()
    }

    public selectedOperation(): ApplyOperationType | null {
        if (this.select == null) {
            return null;
        }
        return this.select.selectedOperation();
    }

    public rentStartDate = () => {
        if (this.select?.isRentSelected()) {
            return this.rentDates?.getValue()?.start
        }
        return null
    }

    public rentEndDate = () => {
        if (this.select?.isRentSelected()) {
            return this.rentDates?.getValue()?.end
        }
        return null
    }

    private manageDateVisibility() {
        if (this.select?.isRentSelected()) {
            this.rentDates?.show()
        } else {
            this.rentDates?.hide()
        }
    }
}