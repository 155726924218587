import {InputErrorManager} from "../InputValidationHandler";
import PageData from "../../../PageData";
import FormValidations from "../FormValidations";
import {ErrorVisibilityManager} from "../ErrorVisibilityManager";

export class MultiMessageEmailInputErrorManager implements InputErrorManager {
    private readonly pageData: PageData;

    constructor(pageData: PageData) {
        this.pageData = pageData;
    }

    manageError(element: HTMLInputElement, isValid: boolean) {
        const emailValue = element.value;
        let errorMessage = null;
        let isEmailValid;
        if (!FormValidations.isNotEmpty(emailValue)) {
            isEmailValid = false;
            errorMessage = this.pageData.validation!!.errorMessages.email.empty;
        } else if (!FormValidations.emailIsValid(emailValue)) {
            isEmailValid = false;
            errorMessage = this.pageData.validation!!.errorMessages.email.incorrectFormat;
        } else {
            isEmailValid = true;
        }

        this.manageErrorMessageWithErrorOf(element, errorMessage, isEmailValid);
    }

    private manageErrorMessageWithErrorOf(element: HTMLInputElement, errorMessage: string | null, isValid: boolean) {
        this.changeErrorMessage(element, errorMessage)
        ErrorVisibilityManager.manageErrorVisibility(element, isValid)
    }

    private changeErrorMessage(element: HTMLInputElement, errorMessage: string | null) {
        let inputWrapper = element.closest('.adform-input-wrapper');
        let errorMessageElement = inputWrapper?.querySelector('.error-text')
        if (errorMessage && errorMessageElement) {
            errorMessageElement.innerHTML = errorMessage;
        }
    }
}