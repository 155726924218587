import PageData from "../../common/ts/PageData";
import ImageGallery from "../../common/ts/components/ImageGallery";
import {EventBus} from "../../common/ts/events/EventBus";
import {GalleryReloadNavigationEvent, GalleryPhotoClickedEvent} from "../../common/ts/events/Events";

export default class HipflatPhotosSection {
    private readonly swiperMobilePhotos;

    private eventBus = EventBus.getInstance();

    constructor(pageData: PageData) {
        this.swiperMobilePhotos = ImageGallery.init(pageData, '.swiper-mobile-photos');

        document.querySelector(".js-image-gallery-container")?.addEventListener('click', () => {
            this.eventBus.emit(new GalleryPhotoClickedEvent({
                slideActive: this.swiperMobilePhotos.getSlideActive(),
            }));
        });

        this.eventBus.subscribe(GalleryReloadNavigationEvent.TYPE, async () => {
            this.swiperMobilePhotos.reloadNavigation();
        });
    }

    static init = (pageData: PageData) => {
        return new HipflatPhotosSection(pageData);
    };
}