import FeatureFlags from "./FeatureFlags";

export default class SplitTests {
    private featureFlags: FeatureFlags;

    constructor() {
        this.featureFlags = new FeatureFlags();
    }

    isActive = (testId: number, variant: number): boolean => {
        const splitTestCookieValue: string = `${testId}-${variant}`;
        const featureFlagActive: boolean = this.featureFlags.isActiveFeature(splitTestCookieValue);
        return featureFlagActive || document.cookie.includes(`_split_test=${splitTestCookieValue}`);
    }

    isTestActive = (testId: number): boolean => {
        return document.cookie.includes(`_split_test=${testId}-`);
    }
};