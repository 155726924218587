import {InputValidator} from "../InputValidationHandler";
import PageData from "../../../PageData";
import {InternationalPhoneInputInterface} from "./InternationalPhoneInput";
import {DefaultPhoneValidator} from "./DefaultPhoneValidator";
import CommonPhoneValidation from "./CommonPhoneValidation"

export class InternationalPhoneValidator implements InputValidator {
    private readonly pageData: PageData;
    private readonly internationalPhoneInput: InternationalPhoneInputInterface;
    private readonly defaultPhoneValidationHandler: InputValidator;

    constructor(pageData: PageData, internationalPhoneInput: InternationalPhoneInputInterface) {
        this.pageData = pageData;
        this.internationalPhoneInput = internationalPhoneInput;
        this.defaultPhoneValidationHandler = new DefaultPhoneValidator(pageData);
    }

    isValid(element: HTMLInputElement) {
        if (CommonPhoneValidation.isMandatoryPhone(this.pageData)) {
            return this.internationalPhoneInput.isPhoneValid();
        }

        return this.defaultPhoneValidationHandler.isValid(element);
    }
}