import SplitTests from "../../SplitTests";

const splitTests: SplitTests = new SplitTests();

export async function expose() {
    // Default options are marked with *
    await fetch("/api/test/expose", {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
    });
}
