
export class HipflatPagination {
    private readonly onPagination: (page: number) => void
    private container: HTMLElement

    static init(onPagination: (page: number) => void ) {
        const pagination = document.getElementsByClassName("pagination").item(0)
        if (pagination != null) {
            new HipflatPagination(onPagination, pagination as HTMLElement)
        }
    }

    constructor(onPagination: (page: number) => void, container: HTMLElement) {
        this.onPagination = onPagination
        this.container = container
        this.subscribeToEvents()
    }

    public refresh() {
        this.subscribeToEvents()
    }

    private subscribeToEvents() {
        let pages= this.container.querySelectorAll(".page")
        if (pages === undefined) {
            return
        }

        pages.forEach((page) => {
            if (!page.classList.contains('page-current')) {
                page.addEventListener('click', () => {

                    const pageNumber = parseInt(page.getAttribute("data-value")!!)

                    this.onPagination(pageNumber);
                });
            }
        });
    }
}