import {InputValidator} from "../InputValidationHandler";
import PageData from "../../../PageData";
import FormValidations from "../FormValidations";

export class DefaultEmailValidator implements InputValidator {
    private readonly pageData: PageData;

    constructor(pageData: PageData) {
        this.pageData = pageData;
    }

    isValid(element: HTMLInputElement) {
        if (this.pageData.emailMandatory) {
            return FormValidations.emailIsValid(element.value);
        }
        return FormValidations.checkOptionalInput(element.value, FormValidations.emailIsValid);
    }
}