import PageData from "../../../PageData";
import FormValidations from "../FormValidations";

export default class CommonPhoneValidation {
    static isMandatoryPhone(pageData: PageData) {
        return pageData.onlyFormContact
            || (pageData.country === 'AE' || pageData.country === 'TH')
            || pageData.brand === 'LAMUDI'
            || pageData.phoneMandatory;
    }

    static isValidPhone(element: HTMLInputElement) {
        return FormValidations.isValidPhone(element.value);
    }
}
