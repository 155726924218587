import PageData from "../PageData";
import AdFormService from "../services/AdFormService";
import FormValidations from "../forms/validator/FormValidations";
import {Section} from "../services/Section";

export default class UserExits {
    private readonly pageData: PageData;
    private readonly backToSerpButtons: NodeListOf<Element>;
    private readonly multiEnquiryForms: NodeListOf<HTMLFormElement>;
    private static hiddenMultiEnquiryClassName = "on-success__multienquiry-wrapper--hidden";

    constructor(
        pageData: PageData,
        backToSerpButtons: NodeListOf<Element>,
        multiEnquiryForms: NodeListOf<HTMLFormElement>
    ) {
        this.pageData = pageData;
        this.backToSerpButtons = backToSerpButtons;
        this.multiEnquiryForms = multiEnquiryForms;
    }


    public static init = (pageData: PageData) => {
        new UserExits(
            pageData,
            document.querySelectorAll('button[data-back-to-serp]'),
            document.querySelectorAll('[data-multienquiry-form]')
        ).setupEventListeners();
    };

    public static sendPostEnquirySimilarsImpressions(
        pageData: PageData,
        showUserExits: HTMLElement | null
    ) {
        if (showUserExits === null || window.getComputedStyle(showUserExits, null).display === "none") {
            return;
        }

        const similarAdIds = Array.from(showUserExits.querySelectorAll('[data-postenquiry-similar-ad]'))
            .map(sim => sim.getAttribute("data-listing-id") as string);
        if (similarAdIds.length > 0) {
            const trackingSection = showUserExits.dataset.postEnquirySimilarsSection as Section;
            new AdFormService().impression(pageData.pageViewId, trackingSection, similarAdIds);
        }
    }

    public static sendMultiEnquiryImpressions(
        pageData: PageData,
        showUserExits: HTMLElement | null
    ) {
        if (showUserExits === null || window.getComputedStyle(showUserExits, null).display === "none") {
            return;
        }

        const multiEnquiryElement: HTMLElement = showUserExits.querySelector('[data-multienquiry-block]')!;
        if (multiEnquiryElement !== null && window.getComputedStyle(multiEnquiryElement, null).display === "none") {
            return;
        }

        const propertyAdIds: string[] = Array.from(showUserExits.querySelectorAll('input[type="checkbox"]'))
            .map((input) => (input as HTMLInputElement).value);

        if (propertyAdIds.length === 0) {
            return;
        }

        const adFormService = new AdFormService();
        const trackingSection = showUserExits.dataset.trackingSection as Section;

        adFormService.impression(pageData.pageViewId, trackingSection, propertyAdIds);
        for (let propertyAdId of propertyAdIds) {
            let multiEnquiryListingPageData = Object.create(pageData);
            multiEnquiryListingPageData.id = propertyAdId;
            adFormService.applyImpression(
                multiEnquiryListingPageData,
                trackingSection,
                1
            )
        }
    }

    private setupEventListeners = () => {
        this.handleUserExitButtons();
        this.handleMultiEnquiryForms();
    };

    private handleUserExitButtons = () => {
        const urlToSerp = localStorage.getItem("lastSerpUrl")
        this.backToSerpButtons.forEach((button) => {
            if (urlToSerp) {
                this.registerRedirectToSerpListener(button, urlToSerp);
            } else {
                this.hideSerpRedirectionElements(button);
            }
        })
    }

    private hideSerpRedirectionElements(button: Element) {
        const continueSearchTitle = document.querySelectorAll('[data-continue-search="true"]');
        const separator = document.querySelectorAll('[data-user-exits-separator="true"]');
        continueSearchTitle.forEach((element) => {
            element.classList.add("exits-message--hidden")
        })
        separator.forEach((element) => {
            element.classList.add("exits-options-separator--hidden")
        })
        button.classList.add("user-exit-button--hidden");
    }

    private registerRedirectToSerpListener(button: Element, urlToSerp: string) {
        button.addEventListener('click', () => {
            window.location.href = urlToSerp;
            // @ts-ignore
            gtag('event', button?.dataset.backToSerp);
        })
    }

    private handleMultiEnquiryForms = () => {
        this.multiEnquiryForms.forEach((form) => {
            const multiEnquirySendButton = form.querySelector('button[data-similars-multienquiry-send]')!;
            const multiEnquiryBlock = form.closest('[data-multienquiry-block]')!;
            const trackingSectionElement: HTMLElement = form.closest('[data-tracking-section]')! as HTMLElement;
            const inputs: NodeListOf<HTMLInputElement> = form.querySelectorAll('input[type="checkbox"]');

            form.onsubmit = () => false

            inputs.forEach((input: HTMLInputElement) => {
                input.addEventListener('change', this.toggleMultienquirySendButton(inputs, multiEnquirySendButton))
            })

            multiEnquirySendButton.addEventListener(
                'click',
                this.sendMultiEnquiryLeads(
                    form,
                    inputs,
                    trackingSectionElement.dataset.trackingSection!,
                    multiEnquiryBlock
                )
            )
        })
    }

    private toggleMultienquirySendButton(inputs: NodeListOf<HTMLInputElement>, multienquirySendButton: Element) {
        return () => {
            const checkedInputs = Array.from(inputs).filter((input: HTMLInputElement) => input.checked)
            if (checkedInputs.length > 0) {
                multienquirySendButton.classList.remove("multienquiry__button--disabled");
            } else {
                multienquirySendButton.classList.add("multienquiry__button--disabled");
            }
        };
    }

    private sendMultiEnquiryLeads(form: HTMLFormElement, inputs: NodeListOf<HTMLInputElement>, trackingSection: string, multienquiryBlock: Element) {
        return () => {
            const message: HTMLTextAreaElement = form.querySelector('textarea[name="multienquiry-message"]')! as HTMLTextAreaElement
            const similarListingsIDs: string[] = []!;

            inputs.forEach((input: HTMLInputElement) => {
                if (input.checked) {
                    similarListingsIDs.push(input.value!)
                }
            })

            similarListingsIDs.forEach((listingId) => {
                new AdFormService().applyContactFormSent(
                    this.multiEnquiryPageData(listingId),
                    localStorage.getItem("name")!,
                    localStorage.getItem("email")!,
                    this.getUserPhone(),
                    FormValidations.buildFormMessage(message),
                    trackingSection
                );
            })
            multienquiryBlock.classList.add(UserExits.hiddenMultiEnquiryClassName);
        };
    }

    private getUserPhone() {
        return localStorage.getItem("phone") !== null ? localStorage.getItem("phone")! : "";
    }

    private multiEnquiryPageData(listingId: string): PageData {
        let multiEnquiryListingPageData = Object.create(this.pageData);
        multiEnquiryListingPageData.id = listingId;
        multiEnquiryListingPageData.cpcClickAttribution = {};

        return multiEnquiryListingPageData;
    }
}
