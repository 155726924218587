import * as EmailValidator from 'email-validator';
import {isEmpty} from "validator";

class FormValidations {
  static emailIsValid = (email: string) => EmailValidator.validate(email);

  static isEmptyInput = (inputValue: string) => inputValue === undefined || inputValue.length === 0;

  static checkOptionalInput = (inputValue: string, isValid: (value: string) => boolean): boolean => {
    return this.isEmptyInput(inputValue) || isValid(inputValue);
  }

  static isEmpty = (value: string) => value === '';

  static isNotEmpty = (value: string) => !isEmpty(value);

  static isValidPhone = (phoneNumber: string): boolean => {
    const isEmpty = () => phoneNumber === undefined || phoneNumber.length === 0;
    return isEmpty() || FormValidations.isValidMandatoryPhone(phoneNumber);
  };

  static isValidMandatoryPhone = (phoneNumber: string): boolean => {
    const isInternational = () => phoneNumber === '+';
    const containsOnlyDigits = () => {
      const digits = (phoneNumber.substr(0, 1) === '+') ? phoneNumber.substr(1) : phoneNumber;
      // eslint-disable-next-line prefer-regex-literals
      const onlyDigitsRegularExpression = new RegExp(/^\d+$/);
      return onlyDigitsRegularExpression.test(digits);
    };
    return isInternational() || containsOnlyDigits();
  };

  static buildFormMessage = (messageElement: HTMLTextAreaElement): string => (messageElement.value === '' ? messageElement.placeholder : messageElement.value);
}

export default FormValidations;
