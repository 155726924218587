import {EventBus} from "../events/EventBus";
import {RefreshSwipeComponentEvent} from "../events/Events";
import AdFormService from "../services/AdFormService";
import {Section} from "../services/Section";
import PageData from "../PageData";

const scheduleTabSelector: string = '.js-scheduleViewing';
const requestTabSelector: string = '.js-requestDetails';
const scheduleConfirmSelector: string = '.js-confirm';

export default class HipflatFormTabs {
    private readonly dateTimeScheduleFormSelector: string = '.js-schedule';
    private readonly requestScheduleFormSelector: string = '.js-request-schedule-form';
    private readonly requestScheduleResumeSelector: string = '.js-schedule-resume';
    private adFormService: AdFormService;
    private readonly onChange: (section: Section) => void;
    private readonly onScheduleConfirm: () => void;
    private readonly scheduleTab: HTMLElement | null
    private readonly requestTab: HTMLElement | null
    private readonly form: HTMLElement

    private constructor(form: HTMLElement, scheduleTab: HTMLElement, requestTab: HTMLElement, pageData: PageData, onChange: (section: Section) => void, onScheduleConfirm: () => void) {
        this.adFormService = new AdFormService()
        this.onChange = onChange;
        this.onScheduleConfirm = onScheduleConfirm;
        this.form = form
        this.scheduleTab = scheduleTab;
        this.requestTab = requestTab;
        this.addEventListeners(pageData);
    }

    static init(form: HTMLElement, pageData: PageData, onChange: (section: Section) => void, onScheduleConfirm: () => void) {
        const scheduleTab = this.firstOrNull(form.querySelectorAll(scheduleTabSelector))
        const requestTab = this.firstOrNull(form.querySelectorAll(requestTabSelector))
        if(scheduleTab != null) {
            new HipflatFormTabs(form, scheduleTab, requestTab, pageData, onChange, onScheduleConfirm)
        }
    }

    static firstOrNull(array: ArrayLike<any>): any | null {
        return Array.from(array).find(() => true)
    }

    private addEventListeners(pageData: PageData) {
        const initialSection: string = this.form.getAttribute('data-form-section') || "";

        this.scheduleTab?.addEventListener('click', () => {
            // Toggle Tabs
            const clickedTab = this.scheduleTab!!;
            clickedTab.classList.add("contact-form__header__schedule-enabled");
            clickedTab.classList.remove("contact-form__header__schedule-disabled");
            this.requestTab?.classList.remove("contact-form__header__details-enabled");
            this.requestTab?.classList.add("contact-form__header__details-disabled");
            let section = Section.SECTION_ADPAGE_TAB_VISIT;
            if (this.form.closest("#modal-photos")){
                section = Section.SECTION_ADPAGE_IMAGE_CAROUSEL_VISIT;
            }
            this.onChange(section)

            // Toggle Content
            const dateTimeScheduleForm = this.form.querySelector(this.dateTimeScheduleFormSelector);
            dateTimeScheduleForm?.classList.remove("hidden");
            dateTimeScheduleForm?.classList.add("flex");
            const requestScheduleForm = this.form.querySelector(this.requestScheduleFormSelector);
            requestScheduleForm?.classList.remove("flex");
            requestScheduleForm?.classList.add("hidden");

            this.adFormService.sendApplyImpression({
                pageViewId: pageData.pageViewId,
                propertyAdId: pageData.id,
                section: section,
                step: 2,
                isProject: false,
            });
            EventBus.getInstance().emit(new RefreshSwipeComponentEvent());
        });

        this.requestTab?.addEventListener('click', () => {
            const clickedTab = this.requestTab!!
            // Toggle Tabs
            clickedTab.classList.add("contact-form__header__details-enabled");
            clickedTab.classList.remove("contact-form__header__details-disabled");

            this.onChange(Section[initialSection as keyof typeof Section]!!)
            this.scheduleTab?.classList.remove("contact-form__header__schedule-enabled");
            this.scheduleTab?.classList.add("contact-form__header__schedule-disabled");

            // Toggle Content
            const dateTimeScheduleForm = this.form.querySelector(this.dateTimeScheduleFormSelector);
            dateTimeScheduleForm?.classList.remove("flex");
            dateTimeScheduleForm?.classList.add("hidden");
            const requestScheduleForm = this.form.querySelector(this.requestScheduleFormSelector);
            requestScheduleForm?.classList.remove("hidden");
            requestScheduleForm?.classList.add("flex");

            const requestScheduleResume = this.form.querySelector(this.requestScheduleResumeSelector);
            requestScheduleResume?.classList.remove("flex");
            requestScheduleResume?.classList.add("hidden");
        });

        const confirmButton = HipflatFormTabs.firstOrNull(this.form.querySelectorAll(scheduleConfirmSelector))
        confirmButton.addEventListener('click', () => {
            const requestScheduleResume = this.form.querySelector(this.requestScheduleResumeSelector);
            requestScheduleResume?.classList.remove("hidden");
            requestScheduleResume?.classList.add("flex");
            const dateTimeScheduleForm = this.form.querySelector(this.dateTimeScheduleFormSelector);
            dateTimeScheduleForm?.classList.remove("flex");
            dateTimeScheduleForm?.classList.add("hidden");
            const requestScheduleForm = this.form.querySelector(this.requestScheduleFormSelector);
            requestScheduleForm?.classList.remove("hidden");
            requestScheduleForm?.classList.add("flex");
            const dateResume = requestScheduleResume?.querySelector(".js-resume__date");
            const timeResume = requestScheduleResume?.querySelector(".js-resume__time");
            const dateInputValue = this.form.querySelector(".js-date-value:checked");
            const timeInputValue = this.form.querySelector(".js-time-value:checked");
            dateResume!.textContent = (dateInputValue as HTMLElement)!.dataset.text || '';
            timeResume!.textContent = (timeInputValue as HTMLElement)!.dataset.text || '';

            this.onScheduleConfirm()
        });
    }
}
