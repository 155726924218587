export default class Logging {
  // TODO Sentry (these methods are here towards a centralized log system)
  static warn = (message: string, ...optionalParams: any[]) => {
    console.warn(message, ...optionalParams);
  };

  static error = (message: string, ...optionalParams: any[]) => {
    console.error(message, ...optionalParams);
  };
}
